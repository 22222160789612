import { openDB } from "idb";

const dbPromise = openDB("imso-app-db", 1, {
  upgrade(db) {
    db.createObjectStore("staff", { keyPath: "uuid" });
    db.createObjectStore("roles", { keyPath: "uuid" });
    db.createObjectStore("modules", { keyPath: "id" });
    db.createObjectStore("vehicles", { keyPath: "uuid" });
    db.createObjectStore("projects", { keyPath: "uuid" });

    db.createObjectStore("staff_preload_data", { keyPath: "uuid" });
    db.createObjectStore("roles_preload_data", { keyPath: "uuid" });
    db.createObjectStore("vehicles_preload_data", { keyPath: "uuid" });
    db.createObjectStore("projects_preload_data", { keyPath: "uuid" });

    db.createObjectStore("my_vehicle_running_logs", { keyPath: "uuid" });
    db.createObjectStore("my_vehicle_repair_logs", { keyPath: "uuid" });
    db.createObjectStore("my_vehicle_fuel_logs", { keyPath: "uuid" });
    db.createObjectStore("my_projects", { keyPath: "uuid" });
    db.createObjectStore("my_vehicles", { keyPath: "uuid" });
    db.createObjectStore("my_leave_applications", { keyPath: "uuid" });
    db.createObjectStore("my_lieu_applications", { keyPath: "uuid" });

    db.createObjectStore("offlineChanges", {
      keyPath: "iDBkey",
      autoIncrement: true,
    });
  },
});

export const db = {
  async getItem(storeName, id) {
    return (await dbPromise).get(storeName, id);
  },
  async getAllItems(storeName) {
    return (await dbPromise).getAll(storeName);
  },
  async addItem(storeName, item) {
    return (await dbPromise).put(storeName, item);
  },
  async editItem(storeName, id, updatedItem) {
    const db = await dbPromise;
    const tx = db.transaction(storeName, "readwrite");
    const store = tx.objectStore(storeName);
    const item = await store.get(id);
    if (item) {
      Object.assign(item, updatedItem);
      await store.put(item);
    }
    await tx.done;
  },

  async deleteItem(storeName, id) {
    return (await dbPromise).delete(storeName, id);
  },

  async addOfflineChange(change) {
    return (await dbPromise).put("offlineChanges", change);
  },
  async getOfflineChanges() {
    return (await dbPromise).getAll("offlineChanges");
  },
  async deleteOfflineChange(id) {
    return (await dbPromise).delete("offlineChanges", id);
  },
  async clearStore(storeName) {
    return (await dbPromise).clear(storeName);
  },
};
